import React, { useState, useEffect,useRef } from "react";



const MyComponent = (MsgSender) => {
  const [sayAnswer, setSayAnswer] = useState(null);
  const audioRef = useRef(null);

  const [isLoading, setIsLoading]= useState(false);
  const [Asked, setAsked] = useState(false);
  const [question, setQuestion] = useState("");
  const [PepeAnswer, setPepeAnswer] = useState("");
  const [isMinting, setIsMinting] = useState(false);
  const [CardNo, setCardNo] = useState("");
  
 
 
////////////



    
   
    


////////////////////////Animation/////////////////////
function  animateText(element) {
  const string = element.innerHTML;
  element.innerHTML = string.replace(/./g, '<span class="new">$&</span>');

  const spans = element.querySelectorAll('span.new');
  spans.forEach((el, i) => {
    setTimeout(() => {
      el.classList.add('div_opacity');
    }, 70 * i);
  });
}
  /////////////////////////////////////////////////////////

async function  askQuestion () {
  if (!Asked) {
    setIsLoading(true);
    // generate random 0 до 21
let rndCard = Math.floor(Math.random() * 22) ;
if (rndCard===0){rndCard=22;};
setCardNo (rndCard);


const tarotCards = [
'The World',         // 0
'The Fool',          // 1
'The Magician',      // 2
'The High Priestess',// 3
'The Empress',       // 4
'The Emperor',       // 5
'The Hierophant',    // 6
'The Lovers',        // 7
'The Chariot',       // 8
'The Force',         // 9
'The Hermit',        // 10
'The Wheel of Fortune', // 11
'The Justice',       // 12
'The Hanged Man',    // 13
'The Death',         // 14
'The Temperance',    // 15
'The Devil',         // 16
'The Tower',         // 17
'The Star',          // 18
'The Moon',          // 19
'The Sun',           // 20
'The Judgement',     // 21
'The World'          // 22
  ];

   const cardis = tarotCards[rndCard];      
   console.log(rndCard, 'Card:', cardis);

 
  const questis = question; 

  try {
    const apiKey = 'YFTGfhjT*h463gHnckf92hgfj60HHf%4dsjz,aeo09wj';

    const response = await fetch(`https://scrolls.chat:3001/api?card=${cardis}&quest=${questis}`, {
        method: 'GET', 
        headers: {
            'Content-Type': 'application/json', 
            'x-api-key': apiKey 
        }
    });
    
    if (!response.ok) {
      throw new Error('error API');
     
    }

    const data = await response.json();
        console.log('Answer', data.answer);
    
    const audioBase64 = data.audio;
    const audioUrl = audioBase64 ? data.audio : null; 
        console.log(audioUrl);
        await setSayAnswer(audioUrl);        
    setPepeAnswer (`${cardis} card! ${data.answer}`);
    setIsMinting(true);
    if (PepeAnswer!=='') {setAsked(true);};
    setIsLoading(false);
      
  } catch (error) {
    console.error('Server answer error:', error);
    
    const safeAnswer = [      
      'Embrace the unknown with an open heart and mind. Trust the journey ahead.',
      'Harness your inner power and creativity to manifest your desires. The universe is on your side.',
      'Intuition and hidden knowledge are key. Trust your inner voice and explore your subconscious.',
      'Nourish your creativity and abundance, embrace femininity and nurturing energy. Trust in your intuition and inner guidance.',
      'Authority, leadership, structure, stability. Take control of the situation with confidence and assertiveness.',
      'Traditional values and institutions are important. Seek guidance from a mentor or wise figure. Embrace conformity and tradition.',
      'The Lovers card represents a deep connection and harmony in relationships, a reminder to follow your heart and make choices based on love.',
      'You are in control of your journey and will overcome obstacles with determination and confidence.',
      'You have the power within you to overcome any obstacles and achieve your goals. Trust in your inner strength and determination.',
      'Take time for introspection and inner guidance. Seek solitude to find the answers within yourself.',
      'A time of change and destiny, things will soon take a turn for the better.',
      'Balanced decisions will bring fairness and clarity to your situation. Trust in the universal laws of justice.',
      'Embrace this time of pause and surrender, trusting that a new perspective will bring clarity and growth.',
      'Transformation and new beginnings are on the horizon. Embrace change and release what no longer serves you.',
      'Find balance and harmony in all aspects of your life. Patience and moderation will lead to success and fulfillment.',
      'Be cautious of temptation and negative influences in your life. Take control and break free from harmful patterns.',
      'Sudden change and upheaval are on the horizon. Embrace the chaos as a catalyst for transformation.',
      'Optimism, hope, and inspiration are on the horizon. Trust in the universe to guide you towards your goals.',
      'Emotions may be clouding your judgment. Trust your intuition and seek clarity in the darkness.',
      'The Sun card represents success, joy, and vitality. It signifies positivity, abundance, and fulfillment in all aspects of life.',
      'A period of reflection and self-evaluation is needed before making a major decision.', 
      'Completion of a cycle. Wholeness. Success and fulfillment. Integration of different aspects of life.',
      'Embrace the unknown with an open heart and mind. Trust the journey ahead.',   
    ];
    const audioArray = [      
	  "https://grandpepe.com/sound/card1.mp3",  
	  "https://grandpepe.com/sound/card2.mp3",
	  "https://grandpepe.com/sound/card3.mp3",
	  "https://grandpepe.com/sound/card4.mp3",
	  "https://grandpepe.com/sound/card5.mp3",
	  "https://grandpepe.com/sound/card6.mp3",
	  "https://grandpepe.com/sound/card7.mp3",
	  "https://grandpepe.com/sound/card8.mp3",
	  "https://grandpepe.com/sound/card9.mp3",
	  "https://grandpepe.com/sound/card10.mp3",
	  "https://grandpepe.com/sound/card11.mp3",
	  "https://grandpepe.com/sound/card12.mp3",
	  "https://grandpepe.com/sound/card13.mp3",
	  "https://grandpepe.com/sound/card14.mp3",
	  "https://grandpepe.com/sound/card15.mp3",
	  "https://grandpepe.com/sound/card16.mp3",
	  "https://grandpepe.com/sound/card17.mp3",
	  "https://grandpepe.com/sound/card18.mp3",
	  "https://grandpepe.com/sound/card19.mp3",
	  "https://grandpepe.com/sound/card20.mp3",
	  "https://grandpepe.com/sound/card21.mp3",
	  "https://grandpepe.com/sound/card22.mp3",
    "https://grandpepe.com/sound/card1.mp3", 
    ];

    const audioUrl = audioArray[rndCard-1] 
    console.log(audioUrl);
    await setSayAnswer(audioUrl);        
    setPepeAnswer (`${cardis} card! ${safeAnswer[rndCard-1]}`);
      setIsMinting(true);
      if (PepeAnswer!=='') {setAsked(true);};
        setIsLoading(false);
  };
  
  
  

  }
};



  useEffect(() => {
    const iframe = document.querySelector('iframe'); 
    if (iframe && iframe.contentDocument) {
      if (PepeAnswer!=='') {
      iframe.contentDocument.getElementById('startt').style.visibility = 'hidden';
      iframe.contentDocument.querySelector('.card-back').classList.add(`card${CardNo}`);
      iframe.contentDocument.getElementById('MInput').value = question;
      iframe.contentDocument.getElementById("MInput").readOnly = 'true';
      iframe.contentDocument.getElementById('cardShow').style.visibility = "visible";
			iframe.contentDocument.querySelector('.card').classList.add('flip');
      
      iframe.contentDocument.getElementById('MInput').style.visibility = 'visible';	
      iframe.contentDocument.getElementById('clickToAsk').style.visibility = 'hidden';
      iframe.contentDocument.getElementById('example').innerHTML = PepeAnswer;
      iframe.contentDocument.getElementById('example').style.visibility = 'visible';
      iframe.contentDocument.getElementById('example').style.alignContent = 'normal';
        
                      
                      const exampleElement = iframe.contentDocument.getElementById('example');
                      animateText(exampleElement);
                    }; 
                                          }
      } , [PepeAnswer, CardNo, question]); 



  
  

  useEffect(() => {
    //AutoplayAudio
    if (sayAnswer && audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error("Cant play audio:", error);
      });
    }
  }, [sayAnswer]);

 
 

  
  return (
    <div className='askDiv'>
      {!isMinting &&<div className='inputCount'>         {75 - question.length} / 75       </div>}
      {!isMinting && <textarea className='inputAsk' maxLength={75}
        type="text"
        placeholder="Type in here to ask Pepe the Soothsayer a question."
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
      />}
      
      <br/>
      {!isMinting &&  <button className='w-button-ask' onClick={() => askQuestion()} disabled={ isLoading ||  question.length < 5}>
        {isLoading  ? "SENDING..." : "ASK"}
      </button>}

      {isMinting && (<div className='mintDiv'><br/><p>You can find Great Pepe's answer is on the top of the picture</p></div>
        )}
        
      
    
    {sayAnswer && (
      <audio className='audio' ref={audioRef} controls>
        <source src={sayAnswer} type="audio/mp3" />        
      </audio> )}
    </div>
  );
};

export default MyComponent;

import "./styles/main.css";
import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import HelloButton from './components/SayHello';
import catsImage from "./Cats00.png"; 

 

const App = () => {
   // const location = useLocation();
   
   const [isIframeLoaded, setIsIframeLoaded] = useState(false);

   const handleIframeLoad = () => {
     setIsIframeLoaded(true); //  iframe load
   };

    return (
      <BrowserRouter>
        
         
        
<div className="navbar-logo-center">

	<div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar-logo-center-container shadow-three w-nav" >
	<div className='reffnavbar'>
  
   
  </div> 
     
			<div className="container-2">
				<div className="navbar-wrapper-three">
					<h1 className="heading">The Grand Pepe's Tarot</h1>
				</div>
			</div>
		<div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
	</div>
</div>
	<div className="w-layout-blockcontainer container w-container">
		
		<div className="div-left">
      
      <iframe className="div-block-frame" onLoad={handleIframeLoad} src="Tarot005.html" title="description"/>
      <div className='div-loading'> Loading...</div>
		</div>		
		<div className="div-block-3">
				<div className="text-block">Do you really need some good advice right now? Then this is what you've been looking for.<br></br>
				<br></br>The Grand Pepe answers any your question using the great power of tarot cards.<br></br><br></br>Thinking about getting married, buying a house, going on vacation or can't decide on a pizza?<br></br><br></br>Ask only the one question that's most important!
				<img src={catsImage} alt="cats" className="cats-image" />
        </div>
		</div>
	</div>
  <div><br/><br/><br/></div>
  {isIframeLoaded && ( 
              <HelloButton/>)}
	<div className="w-layout-blockcontainer container w-container" >
		<div className="text-block">
    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
		<p className='bigp'>FAQ: </p><br/><br/>
What is 'The Grand Pepe's Tarot'?
<p className='darkp'>Its interactive project with Tarot AI Agent inside</p><br/>

How to ask a question?
<p className='darkp'>One visit - one question. <br/>
                      Type your question by clicking at the bottom. After click <br/>
                      on the Ask button and Grand Pepe will shuffle his deck and <br/>
                      then give his answer after seeing the drawed card. 
                      </p><br/>
				


<br/><br/>
Tarot AI is an artificial intelligence that combines the ancient wisdom<br/>
 of Tarot with modern technology. This AI agent doesn't just pull cards, <br/>
 but deeply analyzes their symbolism, offering you personalized predictions, <br/>
 advice, or interpretations depending on your situation.
 <br/><br/><br/>

How Tarot AI works:<br/><br/>
Question Selection: <br/>
<p className='darkp'>The user asks a question or describes their situation.</p>
Interactive layout: <br/>
<p className='darkp'>AI shuffles the virtual deck analyzes the result.</p>
Personal Immersion: <br/>
<p className='darkp'>Mystic AI uses adaptive algorithms to analyze your <br/>
preferences and previous queries, making predictions increasingly accurate <br/>
and personalized.</p><br/><br/><br/>


Features:<br/><br/>
Card Wisdom:<br/>
<p className='darkp'>Uses classic Tarot card meanings, symbolism <br/>and interpretations.</p>
Contextual Analysis:<br/>
<p className='darkp'>Takes into account the tone and essence of your <br/>question to make the prediction as relevant as possible.</p>
Emotional Support:<br/> 
<p className='darkp'>Delivers not only predictions but also inspirational <br/>words to help you cope.</p>
<br/><br/><br/>

This AI agent is perfect for those looking for clues, insight into their lives,<br/>
or just want to embrace the magic of Tarot in the digital age.
<br/><br/><br/><br/><br/><br/>
<p className='twitterp'><a className='twitterlink' href="https://x.com/Pepesoothsayer" target="_blank" rel="noreferrer" >X</a></p>
		</div>
				
    
    
	</div>
          
          {/*  <Footer/>  */}   
          
        </BrowserRouter>
    );  


}


export default App;



